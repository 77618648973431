import { GenerateLink } from "@components/GenerateLink";
import { Upload } from "interfaces/Home.interface";
import Image from "next/image";
import { FC } from "react";
import styles from "./IconListWithLink.module.scss";
export interface IIconListWithLink {
  titulo?: string;
  icons: IIcons[];
  orientation?: string;
}

interface IIcons {
  url: string;
  icon: Upload;
}

const IconListWithLink: FC<IIconListWithLink> = ({
  titulo,
  icons,
  orientation,
}) => (
  <div key={titulo} className={styles.iconFrame}>
    {titulo && <div className={styles.title}>{titulo}</div>}
    <div
      className={
        orientation === "column" ? styles.iconListColunm : styles.iconList
      }
    >
      {icons &&
        icons.map(({ icon, url }) => (
          <GenerateLink key={url} url={url} external={true} type="external">
            <Image
              key={url}
              src={icon.url}
              width={icon.width}
              height={icon.height}
              alt="Imagem"
            />
          </GenerateLink>
        ))}
    </div>
  </div>
);

export default IconListWithLink;
