import { CSSReset, ThemeProvider } from "@chakra-ui/core";
import { ChakraProvider } from "@chakra-ui/react";
import { ModalProvider } from "@components/Modal/provider";
import { theme } from "@src/config/theme";
import CookieProvider from "@src/react-context/cookie-context";
import { SWRConfig } from "swr";
import "../styles/globals.css";
import { FacebookPixel } from "@components/FacebookPixel";
import { GoogleTagManager } from "@components/GoogleTagManager";

function App({ Component, pageProps, gtmId, fbPixelId }) {
  return (
    <>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
        }}
      >
        <ThemeProvider>
          <ChakraProvider theme={theme}>
            <CookieProvider>
              <CSSReset />
              <ModalProvider
                openYourAccountAlert={pageProps?.openYourAccountAlert}
              >
                <Component {...pageProps} />
              </ModalProvider>

              <script
                src={"https://unpkg.com/blip-chat-widget"}
                type={"text/javascript"}
                integrity={
                  "sha384-ouECQb0S113KuvY42WfQQJyQG5GCD4zY6tLs6N4q7F/sPGHHk2W0RKmYG2HtCbr7"
                }
                crossOrigin={"anonymous"}
              ></script>
            </CookieProvider>
          </ChakraProvider>
        </ThemeProvider>
      </SWRConfig>
      <FacebookPixel fbPixelId={fbPixelId} />
      <GoogleTagManager gtmId={gtmId} />
    </>
  );
}

App.getInitialProps = async () => {
  return { gtmId: process.env.GTM_ID, fbPixelId: process.env.FB_PIXEL_ID };
};
export default App;
